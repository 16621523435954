<template>
	
	<div class="box">
		<span class="frame"></span>
		

		<img v-lazy="src" v-if="!href">
		<a target="_blank" v-bind:href="href" v-on:click.prevent="$root.$emit('click', href)" v-if="href"><img v-lazy="src"></a>
		


	</div>

</template>

<script>
	export default {
		name : 'Img',
		props: ['src', 'href']
	}
</script>