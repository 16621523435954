<template>

	<div class="box" v-bind:class="{ center : center, limit : limit, disabled : disabled  }">
		<span class="frame"  v-if="!noframe"></span>
		<button class="btn" v-bind:class="{ transparent : noframe, regular : regular}">


			<a :target="(target)?target : '_blank'" v-bind:href="href" v-on:click.prevent="$root.$emit('click', href)" v-if="href"><slot></slot></a>

			<span @click="click" v-else-if="click"><slot></slot></span>

			<span v-else><slot></slot></span>


		</button>
		
	</div>

</template>

<script>
	export default {
		name : 'Button',
		props : [ 'href', 'click', 'noframe', 'center', 'limit', 'regular', 'disabled', 'target' ]
	}
</script>