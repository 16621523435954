<template>

  	<footer>


  		<div class="labels">
  			<ul>
	  			<li v-bind:class="{active : rollover == 'tshirt'}"><p class="bold center smaller cooper">Merch</p></li>
	  			<li v-bind:class="{active : rollover == 'eyeball'}"><p class="bold center smaller cooper">Videos</p></li>
	  			<li v-bind:class="{active : rollover == 'bird'}"><p class="bold center smaller cooper">Music</p></li>
	  			<li v-bind:class="{active : rollover == 'horse'}"><p class="bold center smaller cooper">Tour</p></li>
	  			<li v-bind:class="{active : rollover == 'arms'}"><p class="bold center smaller cooper">Subscribe</p></li>
	  		</ul>
  		</div>

  		<div class="buttons">

            <div class="sprite tshirt" v-bind:class="{loop : rollover == 'tshirt', active : channel == links.merch}"></div>
            <div class="sprite eyeball" v-bind:class="{loop : rollover == 'eyeball', active : channel == links.videos}"></div>
            <div class="sprite bird" v-bind:class="{loop : rollover == 'bird', active : channel == links.music, active : channel == '/'}"></div>
            <div class="sprite horse" v-bind:class="{loop : rollover == 'horse', active : channel == links.tour}"></div>
            <div class="sprite arms" v-bind:class="{loop : rollover == 'arms', active : channel == links.subscribe}"></div>

            <ul v-on:mouseout="rollover = false">

	  			<li v-on:mouseover="rollover = 'tshirt'" v-if="links.external_merch">
	  				<a :href="links.merch_external" v-on:click.prevent="$root.$emit('click', links.merch_external)" target="_blank"></a>
	  			</li>

	  			<li v-on:mouseover="rollover  = 'tshirt'" v-else-if="!links.external_merch">
	  				<a :href="links.merch" target="_self" v-on:click.prevent="$root.$emit('click', links.merch)"></a>
	  			</li>


	  			<li v-on:mouseover="rollover = 'eyeball'" v-if="links.external_videos">
	  				<a :href="links.videos_external" v-on:click.prevent="$root.$emit('click', links.videos_external)" target="_blank"></a>
	  			</li>

	  			<li v-on:mouseover="rollover = 'eyeball'"  v-else-if="!links.external_videos">
	  				<a :href="links.videos" target="_self" v-on:click.prevent="$root.$emit('click', links.videos)"></a>
	  			</li>

	  			<li v-on:mouseover="rollover = 'bird'" >
	  				<a :href="links.music" target="_self" v-on:click.prevent="$root.$emit('click', links.music)"></a>
	  			</li>

	  			<li v-on:mouseover="rollover = 'horse'" >
	  				<a :href="links.tour" target="_self" v-on:click.prevent="$root.$emit('click', links.tour)"></a>
	  			</li>

	  			<li v-on:mouseover="rollover = 'arms'" >
	  				<a :href="links.subscribe" target="_self" v-on:click.prevent="$root.$emit('click', links.subscribe)"></a>
	  			</li>
	  			
	  		</ul>

  		</div>

    </footer>

</template>

<script>

	export default {
	    name: 'Footer',
	    props:['channel', 'links'],
	    data: () => ({
            rollover : false
        }),
	}
</script>