<template>
  	<div class="menu mobile redtoblack">

  		<div class="menu-wrap">

    		<ul class="nav" v-on:click="$emit('closeMenu')">


    			<li class="purple" v-bind:class="{ active : isActive(links.music) }">
	  				<a :href="links.music" v-on:click.prevent="$root.$emit('click', links.music)">
	  					<v-title
	  						:white="!isActive(links.music)"
	  						:invert="true"
							:lines="1"
				    	>Music</v-title>
	  				</a>
	  			</li>


	  			<li class="red" v-if="links.external_videos" >
	  				<a :href="links.videos_external" v-on:click.prevent="$root.$emit('click', links.videos_external)" target="_blank">
	  					<v-title
	  						:white="true"
	  						:invert="true"
							:lines="1"
				    	>Videos</v-title>
	  				</a>
	  			</li>


	  			
	  			<li class="green" v-bind:class="{ active : isActive(links.videos) }"  v-if="!links.external_videos">
	  				<a :href="links.videos" v-on:click.prevent="$root.$emit('click', links.videos)">
	  					<v-title
	  						:white="!isActive(links.videos)"
	  						:invert="true"
							:lines="1"
				    	>Videos</v-title>
	  				</a>
	  			</li>




	  			<li class="yellow" v-bind:class="{ active : isActive(links.tour) }">
	  				<a :href="links.tour" v-on:click.prevent="$root.$emit('click', links.tour)">
	  					<v-title
	  						:white="!isActive(links.tour)"
	  						:invert="true"
							:lines="1"
				    	>Tour</v-title>
	  				</a>
	  			</li>

	  			<li class="blue" v-bind:class="{ active : isActive(links.subscribe) }">
	  				<a :href="links.subscribe" v-on:click.prevent="$root.$emit('click', links.subscribe)">
	  					<v-title
	  						:white="!isActive(links.subscribe)"
	  						:invert="true"
							:lines="1"
				    	>Subscribe</v-title>
	  				</a>
	  			</li>





	  			<li class="red" v-if="links.external_merch">
	  				<a :href="links.merch_external" v-on:click.prevent="$root.$emit('click', links.merch_external)" target="_blank">
	  					<v-title
	  						:white="true"
	  						:invert="true"
							:lines="1"
				    	>Merch</v-title>
	  				</a>
	  			</li>


	  			<li class="red" v-bind:class="{ active : isActive(links.merch) }" v-if="!links.external_merch">
	  				<a :href="links.merch"  v-on:click.prevent="$root.$emit('click', links.merch)">
	  					<v-title
	  						:white="!isActive(links.merch)"
	  						:invert="true"
							:lines="1"
				    	>Merch</v-title>
	  				</a>
	  			</li>

	  		</ul>


	  		<div class="social">

	  			<div class="menu-end"><span class="line"></span></div>

	  			<ul class="icons">
	  				<li><a :href="links.spotify" v-on:click.prevent="$root.$emit('click', links.spotify)" target="_blank"><i class="icon-spotify"></i></a></li>
	  				<li><a :href="links.apple" target="_blank"><i class="icon-apple"></i></a></li>
	  				<li><a :href="links.instagram" v-on:click.prevent="$root.$emit('click', links.instagram)" target="_blank"><i class="icon-instagram"></i></a></li>
	  				<li><a :href="links.youtube" v-on:click.prevent="$root.$emit('click', links.youtube)" target="_blank"><i class="icon-youtube-play"></i></a></li>
	  				<li><a :href="links.twitter" v-on:click.prevent="$root.$emit('click', links.twitter)" target="_blank"><i class="icon-twitter"></i></a></li>
	  				<li><a :href="links.facebook" v-on:click.prevent="$root.$emit('click', links.facebook)" target="_blank"><i class="icon-facebook"></i></a></li>
	  			</ul>


	  		</div>

		</div>

  	</div>
</template>

<script>

	import Title from '@/Components/partials/Title';
	import Paragraph from '@/Components/partials/Paragraph';
	
	export default {
	    name: 'Menu',
	    props: ['channel', 'links'],
	    components: {
        	'v-title': Title,
        	'v-paragraph' : Paragraph,
        },

        updated() {
            //console.log( this.channel, this.$route.path);
            if( this.channel !=  this.$route.path ){
               this.$emit('toggleMenu');
            }
        },

        methods: {

            isActive(name){
	            return ( this.$options.filters.removeSpecialCharacters(this.channel) === this.$options.filters.removeSpecialCharacters(name) ||  (name === this.links.music && this.channel === '/') ) ? true : false;
            },

        },
	}
</script>