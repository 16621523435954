<template>
  <div class="legals">
    <row class="fixrow" :reverse="false">
      <column class="fixcol" :md="3" :sm="3" :xs="12">
        <v-button :noframe="true" :regular="true" :target="'_self'" :href="links.contact"
          v-on:click.prevent="$root.$emit('click', links.contact)">Contact</v-button>
      </column>

      <column class="fixcol" :md="3" :sm="3" :xs="12">
        <v-button :href="links.privacy" v-on:click.prevent="$root.$emit('click', links.privacy)" :noframe="true"
          :regular="true">Privacy Policy</v-button>
      </column>

      <column class="fixcol" :md="3" :sm="3" :xs="12">
        <v-button :href="links.cookies" v-on:click.prevent="$root.$emit('click', links.cookies)" :noframe="true"
          :regular="true">Cookie Policy</v-button>
      </column>

      <column class="fixcol" :md="3" :sm="3" :xs="12">
        <v-button :href="links.terms" v-on:click.prevent="$root.$emit('click', links.terms)" :noframe="true"
          :regular="true">Terms & Conditions</v-button>
      </column>
    </row>

    <v-title :lines="7">About Thundercat</v-title>

    <v-paragraph class="center">
      In recent years Thundercat, aka Stephen Bruner, has completed his transition from virtuoso bassist to bonafide star
      and cemented his reputation as a wholly unique voice, transcending genre. He has become a cornerstone of the
      Brainfeeder label having released “The Golden Age of Apocalypse” (2011), “Apocalypse” (2013), “Drunk” (2017), and
      his 2020 album “It Is What It Is” won Best Progressive R&amp;B Album at the 63rd GRAMMY Awards, featuring Ty Dolla $ign,
      Childish Gambino, Lil B, Kamasi Washington, Steve Lacy, Steve Arrington, BADBADNOTGOOD, Louis Cole, Pedro Martins
      and Zack Fox. The record was produced by Thundercat and his longtime partner Flying Lotus who was GRAMMY-nominated
      in “Producer of the Year, Non-Classical” for his work on the album.
      <br /><br />
      2023 kicked off with a bang, with Thundercat joining Steve Lacy live onstage at the 65th Annual GRAMMY Awards to
      perform the hit single “Bad Habit.” He also released his latest single “No More Lies” with Tame Impala, and
      announced a slew of tour dates spanning 5 continents.
      <br /><br />
      2022 was a banner year, with highlights including his vocals and bass heavily featuring on Gorillaz single and title
      track “Cracker Island” which hit #1 on the Alternative radio charts. He also starred in the official music video and
      performed onstage with Gorillaz at Coachella and the MTV European Music Awards ceremony. He also joined Red Hot
      Chilli Peppers as support on their North American and EU stadium tour.
      <br /><br />
      Thundercat made his acting debut in 2022 with a recurring role in Star Wars’ “The Book of Boba Fett” on Disney+. He
      also covered “Fly Like An Eagle” for the “Minions: The Rise of Gru” OST executive produced by Jack Antonooff. Other
      film and TV work has included collaborating with Flying Lotus on the original score for “Yasuke” on Netflix, as well
      as an episode of Emmy &amp; Golden Globe winning series “Atlanta” created by Donald Glover.
      <br /><br />
      Live highlights include duetting with Ariana Grande at Adult Swim Festival 2020, a US tour alongside his friend
      Anderson .Paak (2019), an appearance at the Nile Rodgers curated Meltdown Festival held at the Southbank Centre,
      London (2019); and previous festival highlights for Thundercat include Glastonbury, Coachella, Sónar, Fuji Rock,
      Newport Jazz Festival, Primavera Sound and Governors Ball. In 2019 Thundercat was invited by legendary jazz
      institution Blue Note in New York to play a 14 show residency over the space of 6 days. Special guests for the
      residency included Reggie Watts, Zack Fox and Hannibal Buress. One memorable sold out show at New York’s Irving
      Plaza in 2017 featured Dave Chappelle making an impromptu appearance as his hype man.
      <br /><br />
      In 2015 he was “at the creative epicenter” of the 21st century’s most influential hip-hop album Kendrick Lamar’s “To
      Pimp A Butterfly”, and he won a GRAMMY for his collaboration on the track ‘These Walls’.
      <br /><br />
      Much celebrated for his inimitable style, Thundercat has appeared as the face of recent fashion/brand campaigns for
      Kerwin Frost and Kenny Beats, as well as attending Paris Fashion Week as a guest of Spanish luxury designer LOEWE.
      <br /><br />
      Bruner found his instrument at the age of 4. That made him a late-bloomer in the house of Ronald Bruner, Sr., who
      drummed with the Temptations among others. His first bass was a black Harmony, and he practiced to the Ninja Turtles
      soundtrack until pops played him Jaco Pastorius. He joined thrash legends Suicidal Tendencies as a teenager, and has
      spent road and studio time with everyone from Stanley Clarke and Herbie Hancock to Leon Ware, N.E.R.D., Travis
      Scott, HAIM, Silk Sonic (Bruno Mars &amp; Anderson .Paak), Kaytranada, Stevie Wonder and Erykah Badu.
      <br /><br />
      The name Thundercat is a reference to the cartoon he’s loved since childhood and an extension of his wide-eyed,
      vibrant, often superhuman approach to his craft. In 2020 things came full circle when Bruner voiced a character (and
      penned a song) named "Grune the Destroyer" in Cartoon Network’s Thundercats Roar animated series.
    </v-paragraph>

    <v-paragraph class="center bold pad">&copy; Brainfeeder Records. All Rights Reserved</v-paragraph>

    <div class="page-end"><span class="line"></span></div>
  </div>
</template>

<script>
import Paragraph from "@/Components/partials/Paragraph";
import Button from "@/Components/partials/Button";
import Title from "@/Components/partials/Title";

export default {
  name: "Legals",
  props: ["links"],
  components: {
    "v-button": Button,
    "v-paragraph": Paragraph,
    "v-title": Title,
  },
};
</script>
