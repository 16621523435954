<template>

	<div class="box nohover" v-bind:class="{ center : center, limit : limit }" v-if="!cta">
		<span class="frame white-box" v-if="!noframe"></span>
		<article class="white-box">
			<slot></slot>
		</article>
	</div>

	<div class="box nohover" v-else-if="cta">
		<span class="frame white-box" v-if="!noframe"></span>

		<article class="white-box">

			<grid class="fixgrid">
				
				<row
					:reverse="false">

					<column :md="8" :sm="8" :xs="12">
			    		<slot></slot>
			    	</column>

					<column :md="4" :sm="4" :xs="12">
			    		<v-button
			    			:href="href"
			    		>{{cta}}</v-button>
			    	</column>

			    </row>

			</grid>

		</article>

	</div>

</template>

<script>
	import Button from '@/Components/partials/Button';

	export default {
		name : 'Card',
		props: ['href', 'cta', 'noframe', 'center', 'limit'],
		components: {
            'v-button': Button,
        }
	}
</script>