<template>
  	<div class="page yellow">
    	<v-title
			:lines="7"
    	>STYLEGUIDE</v-title>

    	<div class="content">

	    	<div class="guide">
		    	<h3 class="center italic">BODY COPY:</h3>
		    	<v-paragraph class="center">Lorem ipsum dolor sit amet, nullam nam ante tincidunt, pellentesque lorem nibh magnis justo condimentum sollicitudin, diam adipiscing. Et fringilla dui amet, quis nibh ac, cras consequat mauris sapien arcu senectus lacus, condimentum facilisis arcu ornare erat, ut vestibulum odio.</v-paragraph>
		    </div>

		    <div class="guide">

		    	<h3 class="center italic">Cards:</h3>

		    	<v-card><p class="bold">Sub Title</p><p>Line of text under the sub title.</p></v-card>

		    	<v-card
		    		:cta="'wtf'"
		    	><p class="bold">Sub Title</p><p>Line of text under the sub title.</p></v-card>

		    	<v-card
		    		:noframe="true"
		    		:limit="true"
		    		:center="true"
		    	><p class="bold">Test</p><p>Line of text under the sub title and include <font style="color:red">some</font> <a href="#">html</a>.</p></v-card>

		    </div>

		    <div class="guide">
		    	<h3 class="center italic">BUTTONS:</h3>
		    	<v-button>cta</v-button>
		    		
		    	<v-button
		    		:limit="true"
		    		:center="true"
		    	>cta</v-button>

		    	<v-button class="disabled">Disabled</v-button>

		    	<v-button
		    		class="disabled"
		    		:limit="true"
		    		:center="true"
		    	>cta</v-button>

		    </div>

		    <div class="guide">
		    	<h3 class="center italic">INPUT:</h3>
		    	<v-input
		    		:label="true"
		    		>Email</v-input>
		    	<v-input
		    		:limit="true"
		    		:center="true"
		    	></v-input>
		    </div>

		    <div class="guide" >

		    	<h3 class="center italic">IMAGES:</h3>

		    	<row>

				    	<column :md="6" :sm="6" :xs="12">

					    	<v-img
					    		:src="'https://loremflickr.com/400/400'"
					    	></v-img>

					    	<v-button>cta</v-button>

					    </column>

					    <column :md="6" :sm="6" :xs="12">
				    		
					    	<v-img
					    		:src="'https://loremflickr.com/400/400'"
					    	></v-img>

					    	<v-button class="disabled">Disabled</v-button>

					    </column>

					</row>

				

		    </div>

		    <div class="guide">

		    	<h3 class="center italic">VIDEOS:</h3>

		    	
	    		<row
	    			:reverse="false"
	    		>

			    	<column :md="12" :sm="12" :xs="12">

				    	<v-video
				    		:label="true"
				    		:poster="'https://loremflickr.com/160/90'"
				    	>Video One</v-video>

				    </column>

				    <column :md="12" :sm="12" :xs="12">
			    		
				    	<v-video
				    		:label="true"
				    		:poster="'https://loremflickr.com/160/90'"
				    	>Video Two</v-video>


				    </column>

				</row>
				

		    	

		    </div>


		    <div class="guide">

		    	<h3 class="center italic">LEGALS:</h3>

		    	

		    	<row class="fixrow"
		    		:reverse="false"
		    	>

			    	<column class="fixcol" :md="4" :sm="4" :xs="12">

				    	<v-button
				    		:noframe="true"
				    		:regular="true"
				    	>Cta one</v-button>

				    </column>

				    <column class="fixcol" :md="4" :sm="4" :xs="12">
			    		
				    	<v-button
				    		:noframe="true"
				    		:regular="true"
				    	>Cta two</v-button>

				    </column>

				    <column class="fixcol" :md="4" :sm="4" :xs="12">
			    		
				    	<v-button
				    		:noframe="true"
				    		:regular="true"
				    	>Cta three</v-button>

				    </column>

				</row>

				

				<v-paragraph
					class="center bold pad"
				>&copy; Brainfeeder Records. All Rights Reserved</v-paragraph>

				<div class="page-end"><span class="line"></span></div>

		    </div>


		</div>
  	</div>


</template>

<script>
	import Button from '@/Components/partials/Button';
	import Title from '@/Components/partials/Title';
	import Video from '@/Components/partials/Video';
	import Input from '@/Components/partials/Input';
	import Image from '@/Components/partials/Image';
	import Paragraph from '@/Components/partials/Paragraph';
	import Card from '@/Components/partials/Card';

	export default {
		name: 'Stylekit',
        components: {
        	'v-title': Title,
            'v-button': Button,
            'v-video': Video,
            'v-input': Input,
            'v-img': Image,
            'v-paragraph' : Paragraph,
            'v-card' : Card,
        }
    }
</script>