<template>

    <div class="transition-wrapper">


        <slot-machine
            v-if="useSlotMachine"
            v-on:started="onSpinStarted"
            v-on:ended="onSpinEnded"
            :transitioning="transitioning"
        />

        <transition
            v-if="useTransitions"
            v-bind:css="true"
            :name="transitionName"
            :mode="transitionMode"
            :leave-active-class="transitionLeaveActiveClass"
            :enter-active-class="transitionEnterActiveClass"
            @beforeEnter="beforeEnter"
            @enter="enter"
            @leave="leave"
            > 
            <slot/>
        </transition>


        <slot v-if="!useTransitions"/>

    </div>

</template>

<script>

    const DEFAULT_TRANSITION = 'fade';
    const DEFAULT_TRANSITION_MODE = 'out-in';

    //-https://vuejs.org/v2/guide/transitions.html

    //-https://markus.oberlehner.net/blog/vue-router-page-transitions/

    import SlotMachine from '@/components/partials/SlotMachine';

    export default {
        name: 'Transitions',
        data() {
            return {
                useTransitions : false, /* Use transitions? */
                useSlotMachine :  false, /* Use the slot machine transition between pages */
                transitioning : false,
                done : null,
                prevHeight: 0,
                transitionName: DEFAULT_TRANSITION,
                transitionMode: DEFAULT_TRANSITION_MODE,
                transitionEnterActiveClass: '',
                transitionLeaveActiveClass: ''
            };
        },

        components: {
            SlotMachine
        },

        created() {

            //console.log('***TRANSITION', 'created');

            if( this.useTransitions ){


                this.$router.beforeEach((to, from, next) => {

                    console.log( to.name, from.name );

                    let transitionName = to.meta.transitionName || from.meta.transitionName || DEFAULT_TRANSITION;

                    this.transitionMode = DEFAULT_TRANSITION_MODE;
                    this.transitionEnterActiveClass = `${transitionName}-enter-active`;
                    this.transitionLeaveActiveClass = `${transitionName}-leave-active`;
                    this.transitionName = transitionName;

                    next();
                    
                });

            }

        },

        methods: {

            onSpinStarted(){
                //console.log('SLOT MACHINE IS SPINNING');
            },

            onSpinEnded(){
                //console.log('SLOT MACHINE HAS ENDED');
                if( this.done ){
                    this.transitioning = false;
                    this.done();
                }
            },

            beforeEnter(el) {
                //console.log('***TRANSITION', 'beforeEnter');
                el.style.opacity = 0;
            },

            enter(el, done) {
                //console.log('***TRANSITION', 'enter');
                let self = this;
                setTimeout(function(){
                    el.style.opacity = 1;
                    self.done();
                }, 500);
            },

            leave(el, done) {
                //console.log('***TRANSITION', 'leave');
                el.style.opacity = 0;
                if( this.useSlotMachine ){
                    this.done = done;
                    this.transitioning = true;
                }else{
                    done();
                } 
            },
        },
    };

</script>