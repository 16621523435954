<template>
	<h1 class="title" v-bind:class="{invert : invert, white : white, sm: small, shadow:shadow }">
		<div v-for="rowIndex in lines" :key="rowIndex">
			<span v-for="colIndex in 11" :key="colIndex"><slot/></span>
		</div>
	</h1>

</template>

<script>
	export default {
		name : 'Title',
		props:[ 'lines', 'invert', 'white', 'small', 'shadow' ], 
	}
</script>