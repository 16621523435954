<template>

	<div class="page slots">
		<div class="slots-container">
			<div class="slots-panel">
				<div class="icons" v-bind:style="backgroundPosition1"></div>
				<div class="icons" v-bind:style="backgroundPosition2"></div>
				<div class="icons" v-bind:style="backgroundPosition3"></div>
				<!-- <div class="gradient-fade"></div> -->
			</div>
		</div>
    </div>

</template>

<script>
	export default {

		/* 
		++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
		WORK IN PROGRESS. CONVERTING FROM REACT TO VUE FROM
		https://codepen.io/antibland/pen/ypagZd 

		*/

		name : 'SlotMachine',

		props:['transitioning'],

		watch: { 
			transitioning: function(newVal, oldVal) { // watch it
				if ( newVal && newVal != oldVal ){
					this.spin();
				}
			}
		},

		data() {
            return {
			    tick : 100,
			    timer : null,
			    speed : null, 
			    randomise : true, 
			    iconHeight: 188,
			    multiplier:null,
			    matches : [],
			    states : [
			    {
			    	position : 0,
			    	startPosition : 0,
			    	time : 0,
			    	startTime : 1400,
			    	finished : false,
			    },
			    {
			    	position : 0,
			    	startPosition : 0,
			    	time : 0,
			    	startTime : 1400,
			    	finished : false,
			    },
			    {
			    	position : 0,
			    	startPosition : 0,
			    	time : 0,
			    	startTime : 1400,
			    	finished : false,
			    }


			    ],
			};
        },

		computed: {

			backgroundPosition1( ) {
                return `backgroundPosition: 0px  ${ this.states[0].position }px`;
            },

            backgroundPosition2( ) {
                return `backgroundPosition: 0px  ${ this.states[1].position }px`;
            },

            backgroundPosition3( ) {
                return `backgroundPosition: 0px  ${ this.states[2].position }px`;
            }
		    
	  	},

		methods: {

			setStartPosition() {
				return ((Math.floor((Math.random()*9))) * this.iconHeight)*-1;
			},

			setStartTime() {
				return Math.ceil(Math.random()*(50-1)+1) * this.tick;
			},

			reset (){
	  			this.multiplier = Math.floor(Math.random()*(4-1)+1);
	  			this.speed = this.iconHeight * this.multiplier;
	  			for (let i = 0; i < this.states.length; i++) {  
					let state = this.states[i];
					state.finished = false;

					state.time = state.startTime = ( this.randomise  ) ? this.setStartTime() : state.startTime;
					state.position = state.startPosition = ( this.randomise  ) ? this.setStartPosition() : state.startPosition;
					
					//console.log( i, state.time, state.position );
				}
			},

			spin(){
				this.reset();
				this.$emit('started');
		  		this.timer = setInterval( () => {
					this.onTick()
			    }, this.tick);
			},

			onSpinEnd(){
				this.$emit('ended');
			},

			onTick(){

				let totalTime = 0;
				
				for (let i = 0; i < this.states.length; i++) {  

					let state = this.states[i];

					totalTime += state.time;

					if (state.time <= 0 ) {
				    	//clearInterval(this.timer);// stop after first ends    
				    	this.getSymbolFromPosition( state );
				    }else{
				    	state.position -= this.speed;
						state.time -= this.tick;
				    }

				}
				if ( totalTime <= 0) {
					clearInterval(this.timer);// stop after first ends
					this.onSpinEnd();
				}   
			},

			moveBackground() {

				// this.state.position -= this.speed
				// this.state.timeRemaining -= this.tick

  			},

			getSymbolFromPosition( state ) {

				if( state.finished ){
					return  state.finished;
				}

			    const totalSymbols = 9;
			    const maxPosition = (this.iconHeight * (totalSymbols-1)*-1);
			    let moved = (state.startTime/this.tick) * this.multiplier;
			    let currentPosition = state.startPosition;    
			    for (let i = 0; i < moved; i++) {              
					currentPosition -= this.iconHeight;
					if (currentPosition < maxPosition) {
						currentPosition = 0;
					}
			    }
			    //console.log( this.states.indexOf(state), currentPosition );

			    state.finished = true;

			    this.matches.push(currentPosition);

				if (this.matches.length === 3) {
					const first = this.matches[0];
					let results = this.matches.every(match => match === first);
					//console.log( results );
				}

			},
		},

	  	created() {

	  	}

	}
</script>