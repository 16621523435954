<template>
  	<header>

  		<div class="inner">
  			
  			<div class="sprite lightinghands loop"></div>

  			<div class="sprite dragon" v-bind:class="{loop : dragon}" ></div>

  			<div class="sprite cat" v-bind:class="{loop : cat}" ></div>

        	<button class="menu" v-on:click="$emit('toggleMenu')"></button>

        	<div class="hitarea dragon" v-on:mouseover="dragon = true" v-on:mouseout="dragon = false"></div>

  			<div class="hitarea cat" v-on:mouseover="cat = true" v-on:mouseout="cat = false"></div>

        	<div class="social">

	  			<ul class="icons">

	  				<li class="instagram"><a :href="links.instagram" v-on:click.prevent="$root.$emit('click', links.instagram)" target="_blank"></a></li>

	  				<li class="youtube"><a :href="links.youtube" v-on:click.prevent="$root.$emit('click', links.youtube)" target="_blank"></a></li>

	  				<li class="spotify"><a :href="links.spotify" v-on:click.prevent="$root.$emit('click', links.spotify)" target="_blank"></a></li>

	  				<li class="apple"><a :href="links.apple" v-on:click.prevent="$root.$emit('click', links.apple)" target="_blank"></a></li>

	  				<li class="twitter"><a :href="links.twitter" v-on:click.prevent="$root.$emit('click', links.twitter)" target="_blank"></a></li>

	  				<li class="facebook"><a :href="links.facebook" v-on:click.prevent="$root.$emit('click', links.facebook)" target="_blank"></a></li>
	  			</ul>

	  		</div>

    	</div>
    </header>
</template>

<script>
	export default {
	    name: 'Header',
	    props : ['links'],

	    data() {
            return {
            	dragon : false,
            	cat : false
            };
        },
	}
</script>