<template>

  	<div class="contact">

        <row  :reverse="true">
                <column :md="12" :sm="12" :xs="12">
                    <div class="box" >
                        <label><h3 class="bold">Contact</h3>&nbsp;<span class="line"></span></label>
                    </div>
                </column>
            </row>

        <row class="fixrow center" :reverse="false" >

            <column class="fixcol" :md="12" :sm="12" :xs="12">

                <br>
                <br>

                <!-- <div class="page-end"><span class="line"></span></div> -->


                <v-paragraph class="center">Get in touch for bookings and collaborations</v-paragraph>

                <br>
                <br>
                <v-button
                :limit="true"
            :center="true"
                    :target="'_self'"
                    :href="links.contact"
                    >Contact</v-button>

             <!-- <br>
                <br> -->

            </column>

        </row>

  	</div>
    
</template>

<script>

	import Paragraph from '@/Components/partials/Paragraph';
    import Button from '@/Components/partials/Button';
	
	export default {
	    name: 'Contact',
        props : ['links'],
	    components: {
            'v-button': Button,
        	'v-paragraph' : Paragraph,
        }
	}
</script>