<template>
  	<div class="page redtoyellow">

  		<v-title
			:lines="7"
    	>Tour</v-title>

    	<div class="content">

    		<br>

    		<v-subtitle class="center bold">Thundercat Live Dates</v-subtitle>

    		<br>

			<div class="events">
				<div id="seated-55fdf2c0" data-artist-id="ab40edb1-a8a4-4f5d-a611-adee3ffe7276" data-css-version="3"></div>
			</div>


		    <br>
<!--
    		<v-contact
            ></v-contact>
 -->

            <v-legals
                :links="links"
            ></v-legals>

    	</div>

    </div>

</template>

<script>

	import axios from 'axios';
	import VueAxios from 'vue-axios';
	import Title from '@/Components/partials/Title';
	import Button from '@/Components/partials/Button';
	import Subtitle from '@/Components/partials/Subtitle';
	import Paragraph from '@/Components/partials/Paragraph';
	import Card from '@/Components/partials/Card';
	import Legals from '@/Components/partials/Legals';
	import Contact from '@/Components/partials/Contact';

	export default {
	    name: 'Tour',
	    props : ['links', 'track'],
		data() {
            return {
            	disable_songkick : false, // client doesn't know how to use song kick so we are hiding tour dates here instead
		    	songkick :{
		    		data : null,
		    		ready : false,
		    		store_id : 'songkick_thundercat_calendar',
			    	base_url : 'https://api.songkick.com/api/3.0/',
			    	api_key : 'v5qvpGSsfv4KFPg1',
			    	artist : 'Thundercat',
			    	artist_id : 4143636,
			    	total : false,
			    	page : 1,
			    	per_page : 50, //max 50
			    	order: false, //asc or desc
			    	min_date : false, //YYYY-MM-DD
			    	max_date : false, //YYYY-MM-DD

		    	},
		    }
		},
	    components: {
	    	'v-contact': Contact,
        	'v-legals': Legals,
        	'v-title': Title,
        	'v-button': Button,
        	'v-paragraph' : Paragraph,
        	'v-subtitle' : Subtitle,
        	'v-card' : Card,
        },

        mounted() {

        	let stored_data = this.retrieveLocalObject(this.songkick.store_id);

        	if ( stored_data ) {
        		//console.log('USING CACHED SONGKICK DATA');
        		this.track('tour', 'cached', true, false, true );
		    	this.songkick.data = stored_data;
		    	this.songkick.ready = true;
		    }else{
		    	//console.log('FETCHING SONGKICK DATA');
		    	this.track('tour', 'cached', false, false, true );
		    	this.getTourData();
		    }

			const url = "https://widget.seated.com/app.js";
			const scriptTag = document.createElement('script')
			scriptTag.setAttribute('src',url);
			document.body.append(scriptTag);
        },

		methods: {

			millisecondsToMinutes: function(ms) {
			  let minutes = Math.floor(ms / 60000);
			  let seconds = ((ms % 60000) / 1000).toFixed(0);
			  let time = minutes + ":" + (ms < 10 ? '0' : '') + ms;
			  // console.log( time, minutes );
			  return minutes;
			},

			storeLocalObject: function(name, object){
				object.timestamp = Date.now();
				localStorage.setItem(name, JSON.stringify(object));
			},

			retrieveLocalObject:function(name){
				let data = JSON.parse(localStorage.getItem(name));
				return ( data && this.millisecondsToMinutes( Date.now() - data.timestamp ) < 30 ) ? data : false;
			},

			getTourData: function(){

				//console.log( this.songkick );

				let url = this.songkick.base_url + 'artists/' + this.songkick.artist_id  + '/calendar.json?apikey=' + this.songkick.api_key;

				if( this.songkick.page ){
					url += '&page=' + this.songkick.page;
				}

				this.track('tour', 'retrivng', url, false, true );

				// if( !this.songkick.data ) this.songkick.data = {};


				let data;

				axios.get( url ).then(response => ( data = response.data.resultsPage )).catch(error => {
						this.track('tour', 'error', error, false, true );
					}).then(() => {


						//https://www.songkick.com/developer/response-objects#event-object

						if( this.songkick.data ) {
							//console.log( this.songkick.data.results.event.length, data.results.event.length );
							this.songkick.data.results.event = this.songkick.data.results.event.concat(data.results.event); // append more dates!!

						}else{
							this.songkick.data = data;
						}


					   	if( this.songkick.data.status !== 'ok'){
					   		//TO DO
					   	}

					   	if( this.songkick.data.totalEntries > this.songkick.per_page * this.songkick.page ){

					   		this.songkick.page ++;
					   		this.getTourData();
					   	}else if( this.songkick.data ){
				   			this.track('tour', 'success', true, false, true );
							this.storeLocalObject( this.songkick.store_id, this.songkick.data );
							this.songkick.ready = true;
						}else{
							this.track('tour', 'success', false, false, true );
						}

				});


            },

		}
	}
</script>
