<template>



	<div class="form-group">

		<div class="box nohover" v-bind:class="{ center : center, limit : limit, labelabove : label }">
			<span class="frame yellow-box"></span>
			<input type="email" v-model="formdata.email" name="email" class="form-input yellow-box" placeholder="Type your email here" value="" />


			<label class="above" v-if="label"><p class="bold"><slot></slot></p><span class="line transparent"></span></label>

		</div>

	</div>


</template>

<script>
	export default {
		name : 'Input',
		props : [ 'center', 'limit', 'label', 'formdata' ],
	}
</script>