<template>
  	<div class="page bluetopink">

  		<v-title
			:lines="7"
    	>Subscribe</v-title>

    	<div class="content">

    		<!-- <v-paragraph class="center">Latest news about Thundercat</v-paragraph> -->

    		<!-- <br> -->

    		<br>

    		<v-paragraph class="center">Don't miss anything - sign up to the Thundercat and Brainfeeder mailing lists.</v-paragraph>

    		<br>

    		<v-input
	    		:label="true"
	    		:formdata="formdata"
	    	>Email</v-input>

		  <!--   <v-card
	    		:noframe="true"
	    		:limit="true"
	    		:center="true"
	    	><p class="bold">test</p><p>Line of text under the sub title and include <font style="color:red">some</font> <a href="#">html</a>.</p></v-card> -->

	    	<v-button
	    		:disabled="subscribing"
	    		:href="false"
	    		:click="submit"
	    		:limit="true"
	    		:center="true"
	    	>Subscribe</v-button>


	    	<br>


    	<!-- 	<v-contact
            ></v-contact>

 -->
            <v-legals
                :links="links"
            ></v-legals>

    	</div>

    </div>

</template>

<script>

	import axios from 'axios';
    import VueAxios from 'vue-axios';


	// import jsonp from 'jsonp';

	import Title from '@/Components/partials/Title';
	import Button from '@/Components/partials/Button';
	import Paragraph from '@/Components/partials/Paragraph';
	import Card from '@/Components/partials/Card';
	import Input from '@/Components/partials/Input';
	import Legals from '@/Components/partials/Legals';
	import Contact from '@/Components/partials/Contact';
	
	export default {
	    name: 'Subscribe',
	    props : ['links', 'track'],
	    components: {
	    	'v-contact': Contact,
        	'v-legals': Legals,
        	'v-title': Title,
        	'v-button': Button,
        	'v-paragraph' : Paragraph,
        	'v-card' : Card,
        	'v-input': Input,
        },

        data() {
            return {
            	subscribing: false, 
                formdata: {
                    email: ''
                }
            }
        },

        methods: {

        	toggleSubmit(){
        		this.subscribing = !this.subscribing;
        	},

        	resetForm(){
        		this.formdata.email = '';
        	},

        	validateEmail(email) {
			    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			    return re.test(String(email).toLowerCase());
			},

	    	submit() {


	    		let toggleSubmit = this.toggleSubmit;
	    		let resetForm = this.resetForm;

	    		toggleSubmit();

	    		let url = ( window.location.href.search('localhost') > -1 ) ? 'http://localhost:8888/static/subscribe.php' : './subscribe.php';

	    		this.track('form', 'submitted', url, false );

	    		if ( !this.formdata.email || this.formdata.email == '' || !this.validateEmail( this.formdata.email ) ){
	    			this.track('form', 'validated', false, false, true );

					setTimeout(function(){
						toggleSubmit();
					}, 500);
					
	    			return false;
	    		}

	    		this.track('form', 'validated', true, false, true );

	    		this.$recaptcha('homepage').then((token) => {

	    			/*
						TO DO:
						0. Only returns token if not bot
						1. Submit email to server with token
						2. Post secret, response (token) to recaptcha API on server to validate the submission is real (https://www.google.com/recaptcha/api/siteverify)
						3. Save the email somewhere
	    			*/

		    		axios.get( url + "?email=" + this.formdata.email + '&token=' + token )
				    .then(response => {

				    	setTimeout(function(){
							toggleSubmit();
						}, 500);

				    	if( response.data.success){
				    		this.track('form', 'suceess', true, false, true );
				    		resetForm()

				    	}else{
				    		this.track('form', 'suceess', false, false, true );
				    	}

				    })
				    .catch(err => {

				    	this.track('form', 'error', JSON.stringify(err), false, true );

				    	setTimeout(function(){
							toggleSubmit();
						}, 500);
				    	
				    });

					

	   			})
	    	}

	  	},
	}
</script>