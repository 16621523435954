<template>
  	<div class="page pinktosalmon">
    	<v-title
			:lines="7"
    	>page not found</v-title>

    	<div class="content">
    		<v-paragraph class="center">The page you requested doesn't exist. Let me  <a :href="links.music" v-on:click.prevent="$root.$emit('click', links.music)" target="_self" class="white">Show You The Way</a>.</v-paragraph>

    	</div>
  	</div>
</template>

<script>
	import Title from '@/Components/partials/Title';
	import Paragraph from '@/Components/partials/Paragraph';
	
	export default {
	    name: '404',
        props: ['links'],
	    components: {
        	'v-title': Title,
        	'v-paragraph' : Paragraph,
        }
	}
</script>