<template>
  	<div class="page yellowtogreen">
    	<v-title
			:lines="7"
    	>Merch</v-title>

    	<div class="content">

    		<v-paragraph class="center">Visit the Brainfeeder shop for Thundercat Merchandise.</v-paragraph>

            <row class="center" :reverse="false">
                <column :md="6" :sm="6" :xs="12">

                    <v-img
                        :href="links.merch_external"
                        v-on:click.prevent="$root.$emit('click', links.merch_external)"
                        :src="require('../../assets/images/pages/t-shirt.jpg')"
                    >Drank</v-img>

                    <v-button
                        :href="links.merch_external"
                        v-on:click.prevent="$root.$emit('click', links.merch_external)"
                        :limit="true"
                        :center="true"
                    >Shop</v-button>

                </column>
            </row>

     <!--        <v-contact
            ></v-contact>

 -->
            <v-legals
                :links="links"
            ></v-legals>


    	</div>
  	</div>
</template>

<script>

	import Title from '@/Components/partials/Title';
	import Paragraph from '@/Components/partials/Paragraph';
    import Button from '@/Components/partials/Button';
    import Legals from '@/Components/partials/Legals';
    import Image from '@/Components/partials/Image';
    import Contact from '@/Components/partials/Contact';
	
	export default {
	    name: 'Merch',
        props : ['links'],
	    components: {
            'v-contact': Contact,
            'v-legals': Legals,
        	'v-title': Title,
            'v-button': Button,
        	'v-paragraph' : Paragraph,
            'v-img': Image,
        }
	}
</script>