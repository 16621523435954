import Vue from 'vue'
import App from './App.vue'
// import Fallback from './Fallback.vue'
import router from './router'

/* LAZY LOAD */
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
    preLoad: 1.3,
    //error: 'load-error.png' ,
    //loading: 'load-tile.svg',
    attempt: 1,
    listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'touchmove'],
})

/* VUE GRID */
import VueGrid from '@liqueflies/vue-flex-grid' //https://www.npmjs.com/package/@liqueflies/vue-flex-grid AND https://github.com/alexchopin/vue-flexboxgrid (for more docs )
Vue.use(VueGrid)


/* YOURUBE */
import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

/* MOMENTS */
import moment from 'moment' // https://www.npmjs.com/package/vue-moment
Vue.use(require('vue-moment'));


Vue.filter('formatDate', function(str) {
  if (str) {
    return moment(String(str)).format('ddd, Do MMM')
  }
});


Vue.filter('checkHeadline', function(str) {
  return ( str && str != 'Thundercat' ) ? str + ', ' : '';
});


//--https://stackoverflow.com/questions/20421455/how-to-compare-two-string-and-ignoring-special-character-in-c-sharp
Vue.filter('removeSpecialCharacters', function(str) {
  return (typeof str === 'string')  ? str.replace(/[^a-zA-Z ]/g, "") : str;
});


/* RECAPTCHA */
/*
  6Lf5w60UAAAAAJLVY5Ghu6CzUak_shq_B4eQoITQ
  6Lf5w60UAAAAAD9bo16goSxUlbF481MilstD2Ozc
*/
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: '6Lf5w60UAAAAAJLVY5Ghu6CzUak_shq_B4eQoITQ',loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true
}})

/* CONFIG */
Vue.config.productionTip = false;
let compatibleBrowser = typeof Object['__defineSetter__'] === 'function';

const app = new Vue({
	el: '#app',
	router,
	components: {  App },
	template: '<App/>'
})