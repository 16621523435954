import Vue from 'vue'
import Router from 'vue-router'

// Page content
import Music from '@/components/pages/Music'
import Merch from '@/components/pages/Merch'
import Menu from '@/components/pages/Menu'
import Stylekit from '@/components/pages/Stylekit'
import Videos from '@/components/pages/Videos'
import Tour from '@/components/pages/Tour'
import Subscribe from '@/components/pages/Subscribe'
import SlotMachine from '@/components/partials/SlotMachine'

// Fallback page
import PageNotFound from '@/components/pages/404'

Vue.use(Router)

export default new Router({
    //mode : 'hash',
    mode : 'history', //Use History like this... https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Music,
            meta: { transitionName: 'fade' },
        },
        {
            path: '/menu',
            name: 'Menu',
            component: Menu,
            meta: { transitionName: 'fade' },
        },

        {
            path: '/slots',
            name: 'SlotMachine',
            component: SlotMachine,
            meta: { transitionName: 'fade' },
        },

        {
            path: '/stylekit',
            name: 'Stylekit',
            component: Stylekit,
            meta: { transitionName: 'fade' },
        },

        {
            path: '/music',
            name: 'Music',
            component: Music,
            meta: { transitionName: 'fade' },
        },
        {
            path: '/merch',
            name: 'Merch',
            component: Merch,
            meta: { transitionName: 'fade' },
        },
        {
            path: '/videos',
            name: 'Videos',
            component: Videos,
            meta: { transitionName: 'fade' },
        },
        {
            path: '/tour',
            name: 'Tour',
            component: Tour,
            meta: { transitionName: 'fade' },
        },
        {
            path: '/subscribe',
            name: 'Subscribe',
            component: Subscribe,
            meta: { transitionName: 'fade' },
        },
        {
            path: '**',
            name: 'PageNotFound',
            component: PageNotFound,
            meta: { transitionName: 'fade' },
        }
    ]

})