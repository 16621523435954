<template>
  	<section class="screen">
        <div class="screen-inner">
            <div class="screen-content">
                
                <Menu
                    :links="links"
                    :channel="channel"
                    v-on:closeMenu="onCloseMenu"
                    v-if="menu"/>

                <transitions v-if="!menu">
                    <Stylekit v-if="stylekit"/>
                    <router-view :track="track" :links="links" v-else-if="!stylekit"></router-view>
                </transitions>

                <span class="noise"></span>

            </div>
        </div>
        <div class="screen-frame"></div>
    </section>
</template>

<script>
    import Transitions from '@/components/Transitions';
    import Stylekit from '@/components/pages/Stylekit';
	import Menu from '@/components/pages/Menu';
    // import Slots from '@/components/partials/Slots';

	export default {
	    
        name: 'Screen',
        props: [ 'stylekit', 'menu', 'channel', 'links', 'track'],

        created() {
            this.$emit('updated', this.$route.path );
        },

        updated() {
            this.$emit('updated', this.$route.path );
        },

        methods: {
            onCloseMenu(e){
                this.$emit('closeMenu');
            },
        },

	    components: {
            // Slots,
            Stylekit,
            Transitions,
            Menu
        }
	}
</script>