<template>

	
  	<div class="page greentoblue">

  		<v-title
			:lines="7"
    	>Videos</v-title>

    	<div class="content">

    		<v-paragraph class="center">Check out the official video for Dragonball Durag below or head over to Thundercat's YouTube Channel and subscribe.</v-paragraph>

    		<br>

    		<div class="g-ytsubscribe" data-channelid="UCQB4-ZjPDfiG1-k0KZlbPug" data-layout="default" data-count="default"></div>

    		<br>
    		<br>
    		<br>

    		<row
	    		:reverse="false">

		    	<column :md="12" :sm="12" :xs="12">

			    	<v-video
			    		v-on:playing="onPlaying"
			    		v-on:ready="onReady"
			    		:label="true"
			    		:youtube="'ormQQG2UhtQ'"
			    		:pl_index="null"
			    		:poster="'https://img.youtube.com/vi/ormQQG2UhtQ/sddefault.jpg'"
			    	>Dragonball Durag</v-video>

			    </column>

			</row>

			<row
	    		:reverse="false">

		    	<column :md="12" :sm="12" :xs="12">

			    	<v-video
			    		v-on:playing="onPlaying"
			    		v-on:ready="onReady"
			    		:label="true"
			    		:youtube="'GNCd_ERZvZM'"
			    		:pl_index="null"
			    		:poster="'https://img.youtube.com/vi/GNCd_ERZvZM/sddefault.jpg'"
			    	>Them Changes</v-video>

			    </column>

			</row>

    		<row
	    		:reverse="false">

			    <column :md="12" :sm="12" :xs="12">
		    		
			    	<v-video
			    		v-on:playing="onPlaying"
			    		v-on:ready="onReady"
			    		:label="true"
			    		:youtube="'Z-zdIGxOJ4M'"
			    		:pl_index="null"
			    		:poster="'https://img.youtube.com/vi/Z-zdIGxOJ4M/sddefault.jpg'"
			    	>Show You The Way</v-video>

			    </column>

			</row>

			<row
				:reverse="false">

		    	<column :md="12" :sm="12" :xs="12">

			    	<v-video
			    		v-on:playing="onPlaying"
			    		v-on:ready="onReady"
			    		:label="true"
			    		:youtube="'8qGigIMM1Vw'"
			    		:pl_index="null"
			    		:poster="'https://img.youtube.com/vi/8qGigIMM1Vw/sddefault.jpg'"
			    	>Walkin</v-video>

			    </column>

				   
			</row>


			<row
    			:reverse="false">

		    	<column :md="12" :sm="12" :xs="12">

			    	<v-video
			    		v-on:playing="onPlaying"
			    		v-on:ready="onReady"
			    		:label="true"
			    		:youtube="'SZzjw2UfUEk'"
			    		:pl_index="null"
			    		:poster="'https://img.youtube.com/vi/SZzjw2UfUEk/sddefault.jpg'"
			    	>Tokyo</v-video>

			    </column>

			   

			</row>


			<row
    			:reverse="false">

		    	<column :md="12" :sm="12" :xs="12">

			    	<v-video
			    		v-on:playing="onPlaying"
			    		v-on:ready="onReady"
			    		:label="true"
			    		:youtube="'CEZ-aJYFJfE'"
			    		:pl_index="null"
			    		:poster="'https://img.youtube.com/vi/CEZ-aJYFJfE/sddefault.jpg'"
			    	>Song for the Dead</v-video>

			    </column>

			   

			</row>


			<row
    			:reverse="false">

		    	<column :md="12" :sm="12" :xs="12">

			    	<v-video
			    		v-on:playing="onPlaying"
			    		v-on:ready="onReady"
			    		:label="true"
			    		:youtube="'OWQ1E66Bbd8'"
			    		:pl_index="null"
			    		:poster="'https://img.youtube.com/vi/OWQ1E66Bbd8/sddefault.jpg'"
			    	>Evangellion/We’ll Die</v-video>

			    </column>

			   

			</row>



			<row
    			:reverse="false">

		    	<column :md="12" :sm="12" :xs="12">

			    	<v-video
			    		v-on:playing="onPlaying"
			    		v-on:ready="onReady"
			    		:label="true"
			    		:youtube="'o9wipZFDoHQ'"
			    		:pl_index="null"
			    		:poster="'https://img.youtube.com/vi/o9wipZFDoHQ/sddefault.jpg'"
			    	>Tron Song</v-video>

			    </column>

			   

			</row> 

		<!-- 	<v-contact
            ></v-contact>

 -->
            <v-legals
                :links="links"
            ></v-legals>

    	</div>

    </div>
</template>

<script>
	import Title from '@/Components/partials/Title';
	import Button from '@/Components/partials/Button';
	import Paragraph from '@/Components/partials/Paragraph';
	import Video from '@/Components/partials/Video';
	import Legals from '@/Components/partials/Legals';
	import Contact from '@/Components/partials/Contact';
	
	export default {
	    name: 'Videos',
	    props : ['links'],
	    data() {
			return {
	        	videos : new Array()
	        }
        },
	    components: {
	    	'v-contact': Contact,
        	'v-legals': Legals,
        	'v-title': Title,
        	'v-button': Button,
        	'v-paragraph' : Paragraph,
        	'v-video': Video,
        },


        created () {
        	let ckeditor = document.createElement('script');
        	ckeditor.setAttribute('src',"//apis.google.com/js/platform.js");
        	document.head.appendChild(ckeditor);
        },

        methods: {

        	onReady( video ) {
        		console.log( 'VIDEO READY' );
        		this.videos.push( video );
		    },

        	onPlaying( currentvideo ) {
        		console.log( 'VIDEO PLAYING' );
        		this.videos.forEach(function(video) {
        			if( video !=  currentvideo ){
        				video.stopVideo();
        			}
				});
		    },
        }
	}
</script>