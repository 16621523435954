<template>

	<div class="box" v-bind:class="{ labelbelow : label }" >

		<span class="frame"></span>

		<div class="video" v-bind:class="{ playing : playing, poster : poster }">

			<!-- <img class="poster" v-lazy="src"> -->

			<div class="youtube-holder sixteen-nine"" v-if="youtube">
				<youtube
					class="youtube"
					ref="youtube"
					:player-vars="playerVars"
					:video-id="youtube"
					@ready="onReady"
					@ended="onEnded"
					@playing="onPlaying"
					@paused="onPaused"
				></youtube>
			</div>

			<div class="img" v-bind:style="{ backgroundImage: 'url(' + poster + ')' }" v-if="poster"></div>

			<div class="overlay" @click="playVideo" v-if="ready">
				<v-title
					:white="true"
					:invert="true"
					:lines="1"
					:small="true"
					:shadow="true"
				>Play</v-title>
			</div>

		</div>

		<label class="above" v-if="label"><p class="bold"><slot></slot></p><span class="line"></span></label>

	</div>

</template>

<script>
	import Title from '@/Components/partials/Title';

	export default {
		name : 'Video',
		props: ['poster', 'youtube', 'label', 'pl_index'], //TO DO: Add HTML5 video support
		components: {
        	'v-title': Title
        },
        data() {
		    return {
		    	playlist : 'PLhWWVi-OXMCD7Na72avtlIy6Kw_McuXWy',
				playerVars: {
					autoplay: 0,
					disablekb: 1,
					modestbranding: 1,
					origin: window.location.origin,
					suggestedQuality: 'hd720',// 'default' no longer works, but others do http://goo.gl/Ua6gEU
				},
		    	ready: false,
		    	playing: false,
		    }
		},

		methods: {

		    playVideo( e ) {
		    	console.log('PLAY VIDEO');
		    	this.player.playVideo();
		    },

		    stopVideo( e ) {
		    	this.player.pauseVideo();
		    },

		    onReady( e ) {
		    	if ( this.playlist && this.pl_index ){

		    		this.player.cuePlaylist({
				        listType: 'playlist',
				        list: this.playlist,
				        index: this.pl_index - 1
				    });
		    	}

		    	this.ready = true;
		    	this.$emit('ready', this);
		    },

		    onEnded( e ) {
		    	this.playing = false;
		    },

		    onPlaying( e ) {
		    	this.$emit('playing', this);
		    	this.playing = true;
		    },

		    onPaused( e ) {
		    	this.playing = false;
		    },

		},

		computed: {
		    player() {
		    	return this.$refs.youtube.player;
		    }
	  	}
	}
</script>