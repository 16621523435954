<template>
  	<div class="page yellowtored">

  		<v-title
			:lines="7"
    	>Music</v-title>

    	<div class="content">

    		<br>

    		<v-subtitle class="center bold">"No More Lies" - Out Now</v-subtitle>

    		<br>

    		<v-paragraph class="center">New single featuring Tame Impala</v-paragraph>

    		<br>

			<v-paragraph class="center">New tour dates and merch now on sale</v-paragraph>

			<br>


			<row class="center" :reverse="false">


				<column :md="6" :sm="6" :xs="12">

		    		<v-img
			    		:href="'https://thundercat.lnk.to/no-more-lies'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/no-more-lies')"
			    		:src="require('../../assets/images/music/single-no-more-lies.jpg')"
			    	>It Is What It Is</v-img>

			    	<v-button
			    	:href="'https://thundercat.lnk.to/no-more-lies'"
			    	v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/no-more-lies')"
			    	>Listen/Buy</v-button>

			    	<!-- <v-button
			    	:href="'https://thundercat.lnk.to/duragAP'"
			    	v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/duragAP')"
			    	>Listen to Dragonball Durag</v-button> -->

		    		
    			</column>


                
    			
            </row>



            
            
            
            	<v-contact
            	:links="links"
			></v-contact>


        



            <!-- <v-paragraph class="center">The Thundercat Pachinko Experience</v-paragraph> -->


            <!--

            <row  :reverse="true">
				<column :md="12" :sm="12" :xs="12">
					<div class="box" >
						<label><p class="bold">Thundercat</p><span class="line"></span></label>
					</div>
				</column>
			</row>


			<br>
			<br>

    		<v-paragraph class="center">Thundercat takes his jazz roots and works with a mix of artists that suit his wildly experimental sensibilities to push genres further, blurring the confines of pop, funk, electronica and prog rock, and creating something else entirely.</v-paragraph>

    		-->


			<br>



    		<row  :reverse="true">
				<column :md="12" :sm="12" :xs="12">
					<div class="box" >
						<label><h3 class="bold">Albums</h3>&nbsp;<span class="line"></span></label>
					</div>
				</column>
			</row>

			<br>

	    	<row  :reverse="false">

				<column :md="6" :sm="6" :xs="12">

		    		<v-img
			    		:href="'https://thundercat.lnk.to/it-is-what-it-isAP'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/it-is-what-it-isAP')"
			    		:src="require('../../assets/images/music/album-it-is-what-it-is.jpg')"
			    	>It Is What It Is</v-img>

			    	<v-button
			    	:href="'https://thundercat.lnk.to/it-is-what-it-isAP'"
			    	v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/it-is-what-it-isAP')"
			    	>Listen/Buy</v-button>
		    		
    			</column>

		    	<column :md="6" :sm="6" :xs="12">

			    	<v-img
			    		:href="'https://thundercat.lnk.to/drankAP'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/drankAP')"
			    		:src="require('../../assets/images/music/album-drank.jpg')"
			    	>Drank</v-img>

			    	<v-button
			    	:href="'https://thundercat.lnk.to/drankAP'"
			    	v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/drankAP')"
			    	>Listen / Buy</v-button>

			    </column>

			 

			</row>


			<row  :reverse="false">

				<column :md="6" :sm="6" :xs="12">
		    		
			    	<v-img
			    		:href="'https://thundercat.lnk.to/drunkAP'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/drunkAP')"
			    		:src="require('../../assets/images/music/album-drunk.jpg')"
			    	>Drunk</v-img>

			    	<v-button
			    	:href="'https://thundercat.lnk.to/drunkAP'"
			    	v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/drunkAP')"
			    	>Listen / Buy</v-button>

			    </column>

		    	<column :md="6" :sm="6" :xs="12">

			    	<v-img
			    		:href="'https://thundercat.lnk.to/tbAP'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/img')"
			    		:src="require('../../assets/images/music/album-the-beyond-where-the-giants-roam.jpg')"
			    	>The Beyond / Where The Giants Roam</v-img>

			    	<v-button
			    		:href="'https://thundercat.lnk.to/tbAP'"
			    	>Listen / Buy</v-button>

			    </column>

			  

			</row>

			<row  :reverse="false">

				<column :md="6" :sm="6" :xs="12">
		    		
			    	<v-img
			    		:href="'https://thundercat.lnk.to/apAP'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/apAP')"
			    		:src="require('../../assets/images/music/album-apocalypse.jpg')"
			    	>Apocalypse</v-img>

			    	<v-button
			    	:href="'https://thundercat.lnk.to/apAP'"
			    	v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/apAP')"
			    	>Listen / Buy</v-button>

			    </column>

		    	<column :md="6" :sm="6" :xs="12">
		    		
			    	<v-img
			    		:href="'https://thundercat.lnk.to/gaAP'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/gaAP')"
			    		:src="require('../../assets/images/music/album-the-golden-age-of-apocalypse.jpg')"
			    	>The Golden Age Of Apocalypse</v-img>

			    	<v-button
			    		:href="'https://thundercat.lnk.to/gaAP'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/gaAP')"
			    	>Listen / Buy</v-button>

			    </column>
			</row>



			<row  :reverse="true">
				<column :md="12" :sm="12" :xs="12">
					<div class="box" >
						<label><h3 class="bold">Singles</h3>&nbsp;<span class="line"></span></label>
					</div>
				</column>
			</row>

			<br>


			<row  :reverse="false">

		    	<column :md="6" :sm="6" :xs="12">
		    		
			    	<v-img
			    		:href="'https://thundercat.lnk.to/ffbbAP'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/ffbbAP')"
			    		:src="require('../../assets/images/music/single-final-fight-bowzer-s-ballad.jpg')"
			    	>Final Fight / Bowser's Ballad</v-img>

			    	<v-button
			    		:href="'https://thundercat.lnk.to/ffbbAP'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/ffbbAP')"
			    	>Listen / Buy</v-button>

			    </column>


			    <column :md="6" :sm="6" :xs="12">
		    		
			    	<v-img
			    		:href="'https://thundercat.lnk.to/fightAP'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/fightAP')"
			    		:src="require('../../assets/images/music/single-final-fight.jpg')"
			    	>Final Fight</v-img>

			    	<v-button
			    		:href="'https://thundercat.lnk.to/fightAP'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/fightAP')"
			    	>Listen / Buy</v-button>

			    </column>

			
			</row>


			<row  :reverse="false">

		    	<column :md="6" :sm="6" :xs="12">
		    		


		    		<!-- <img src="@/assets/images/music/single-bus-in-these-streets.jpg"> -->


			    	<v-img
			    		:href="'https://thundercat.lnk.to/bitsAP'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/bitsAP')"
			    		:src="require('../../assets/images/music/single-bus-in-these-streets.jpg')"
			    	>Bus In These Streets</v-img>

			    	<v-button
			    		:href="'https://thundercat.lnk.to/bitsAP'"
			    		v-on:click.prevent="$root.$emit('click', 'https://thundercat.lnk.to/bitsAP')"
			    	>Listen / Buy</v-button>

			    </column>


			   

			    

			</row>


		

			<v-legals
				:links="links"
			></v-legals>


		</div>

  	</div>
</template>

<script>
	import Title from '@/Components/partials/Title';
	import Button from '@/Components/partials/Button';
	import Paragraph from '@/Components/partials/Paragraph';
	import Subtitle from '@/Components/partials/Subtitle';
	import Image from '@/Components/partials/Image';
	import Legals from '@/Components/partials/Legals';
	import Contact from '@/Components/partials/Contact';

	export default {
	    name: 'Music',
	    props : ['links'],
	    components: {
        	'v-contact': Contact,
        	'v-legals': Legals,
        	'v-title': Title,
        	'v-button': Button,
        	'v-paragraph' : Paragraph,
        	'v-subtitle' : Subtitle,
        	'v-img': Image,

        }
	}
</script>